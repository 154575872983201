<template>
  <div class="faq-form">
    <v-form ref="form" class="faq-form__form" @submit.prevent="submit">
      <h3 class="py-5 primary--text">{{ header }}</h3>

      <span class="red--text"><strong>* </strong></span
      ><label class="text-field-label">Building</label>
      <SelectMultipleBuildings
        flat
        solo
        required
        class="mt-2"
        :label="'Select buildings'"
        :placeholder="'Select buildings'"
        v-model="form.building_id"
        :pre-select="!isUpdate"
        :error-messages="form.$getError('building_id')"
        :loading="form.$busy"
        :disabled="form.$busy"
        :deletableChips="true"
        :isHasSelectAll="true"
        :items="faq ? faq.buildings : []"
      />

      <span class="red--text"><strong>* </strong></span
      ><label class="text-field-label">Title</label>
      <v-text-field
        flat
        solo
        required
        class="mt-2"
        placeholder="Title"
        v-model="form.title"
        :error-messages="form.$getError('title')"
        :loading="form.$busy"
        :disabled="form.$busy"
      ></v-text-field>

      <span class="red--text"><strong>* </strong></span
      ><label class="text-field-label">Header</label>
      <v-text-field
        flat
        solo
        required
        class="mt-2"
        placeholder="Header"
        v-model="form.header"
        :error-messages="form.$getError('header')"
        :loading="form.$busy"
        :disabled="form.$busy"
      ></v-text-field>

      <span class="red--text"><strong>* </strong></span
      ><label class="text-field-label">Content</label>
      <v-textarea
        flat
        solo
        required
        class="mt-2"
        placeholder="Content"
        v-model="form.content"
        :error-messages="form.$getError('content')"
        :loading="form.$busy"
        :disabled="form.$busy"
      ></v-textarea>

      <label class="text-field-label">Note</label>
      <v-textarea
        flat
        solo
        required
        class="mt-2"
        placeholder="Note"
        v-model="form.note"
        :error-messages="form.$getError('note')"
        :loading="form.$busy"
        :disabled="form.$busy"
      ></v-textarea>

      <div class="mt-4">
        <v-btn
          v-if="hasUpdatePermission"
          type="submit"
          color="primary"
          class="mr-4 px-6"
          height="40px"
          width="100%"
          :loading="form.$busy"
          >{{ buttonLabel }}</v-btn
        >
      </div>
    </v-form>
  </div>
</template>
<script>
/**
 * ==================================================================================
 * Hub FAQ Form
 * ==================================================================================
 **/
import { mapState, mapActions } from 'vuex'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import Form from '@/utils/form'
import { validatePermissions } from '@/utils/auth'
import SelectMultipleBuildings from '@/components/fields/SelectMultipleBuildings.vue'
import PERMISSION from '@/utils/enums/Permission'

export default {
  components: {
    SelectMultipleBuildings,
  },

  mixins: [SnackbarMixin, ErrorHandlerMixin],

  props: {
    faq: {
      type: Object,
      default: () => {
        return null
      },
    },
    accessBuilding: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      form: new Form({
        building_id: [],
        title: '',
        header: '',
        content: '',
        note: '',
      }),
    }
  },

  computed: {
    ...mapState({
      createdFaq: (state) => state.faq.faqDetails,
      permissions: (state) => state.auth.permissions,
    }),

    header() {
      return this.isUpdate ? 'FAQ Information' : 'Create FAQ'
    },

    buttonLabel() {
      return this.isUpdate ? 'Update' : 'Create'
    },

    submitApi() {
      return this.isUpdate ? this.updateFaq : this.createFaq
    },

    isUpdate() {
      return !!this.faq
    },

    hasUpdatePermission() {
      if (this.isUpdate) {
        return (
          validatePermissions([PERMISSION.FAQS_UPDATE], this.permissions) &&
          this.accessAllBuilding
        )
      } else {
        return validatePermissions([PERMISSION.FAQS_CREATE], this.permissions)
      }
    },
  },

  watch: {
    faq(newValue, oldValue) {
      this.initForm()
    },
  },

  methods: {
    ...mapActions({
      createFaq: 'faq/createFaq',
      updateFaq: 'faq/updateFaq',
    }),

    initForm() {
      if (this.faq) {
        this.form.title = this.faq.title
        this.form.header = this.faq.header
        this.form.content = this.faq.content
        this.form.note = this.faq.note

        const buildingIds = [...this.faq.buildings].map((item) => item.id)
        this.form.building_id = buildingIds
      }
    },

    async submit() {
      if (this.form.$busy) return

      this.form.$busy = true
      this.form.$clearErrors()

      let forUpdate = this.isUpdate
      await this.submitApi(this.getFormData())
        .then(() => {
          if (forUpdate) {
            this.form.$busy = false
            this.showSnackbar('FAQ details successfully updated!')
          } else {
            this.showSnackbar('FAQ successfully created!')
            this.$router.push({
              name: 'hub.faqs',
            })
          }
        })
        .catch((err) => {
          this.form.$busy = false
          this.form.$setErrors(this.getValidationErrors(err))
        })
    },

    getFormData() {
      let form = this.form.$data()
      if (this.isUpdate) {
        form.id = this.faq.id
      }

      return form
    },
  },
}
</script>
<style lang="scss">
.faq-form {
  &__form {
    max-width: 400px;
  }

  @media (max-width: 768px) {
    &__form {
      max-width: 100%;
    }
  }
}
</style>
